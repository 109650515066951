import './App.css';
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Services from "./components/Services";
import About from "./components/About";
import Projects from "./components/Projects";

function App() {
    return(
        <>
            <Navbar/>
            <Hero/>
            <About/>
            <Services/>
            <Projects/>
            <Footer/>
        </>
    );
};

export default App;
