import discover from './images/Discover.png'
import tire from './images/Tiresense.png'
import emr from './images/EMR.png'
// import tour4 from './images/tour-4.jpeg'
// import tour5 from './images/tour-5.jpeg'
// import tour6 from './images/tour-6.jpeg'

export const pageLinks = [
    {id: 1, href: '#home', text: 'home'},
    {id: 2, href: '#about', text: 'about'},
    {id: 3, href: '#services', text: 'services'},
    {id: 4, href: '#projects', text: 'projects'},
];

export const socialLinks = [
    {id:1,href:"https://www.linkedin.com/in/rafaelpolanski/",icon:'fab fa-linkedin-in'},
];

export const services = [
    {id: 1, icon: "fa-brands fa-uncharted", title: 'Product Management', text: 'Discover the desirability, feasibility, and viability of new business models'},
    {id: 2, icon: "fa-brands fa-searchengin", title: 'Engineering Management', text: 'Create a high performing and satisfied team, focusing on high throughput'},
    {id: 3, icon: "fa-solid fa-pen-clip", title: 'Design Thinking', text: 'Problem solving, hypothesis validation, and ideation'},
    {id: 4, icon: "fa-solid fa-robot", title: 'Machine Learning', text: 'Predict the future based on the past'},
]

export const projects = [
    {id: 1, image: tire, date: '2018-2020', title: 'Tire Sensor', info: 'A new business model and product from seed idea to implementation, creating equipment to read tire wear data, and applying predictive analytics to support data monetization strategies with manufacturers.'},
    {id: 2, image: emr, date: '2020-2021', title: 'ExxonMobil Rewards+', info: 'The ExxonMobil Rewards+ App for United States, and SpeedPass+ for Canada are Consumer Face apps, being a huge opportunity I had to focus on B2C.'},
    {id: 3, image: discover, date: '2023-2024', title: 'Discover', info: 'Geo-spatial capabilities to show leak inspection data across the United States, keeping communities safe, as well as selling products overseas.'},
]