import Title from "./Title";
import {projects} from "../data";

const Projects = () => {
    return (
        <section className="section" id="projects">
            <Title title='my ' subTitle='projects'/>

            <div className="section-center featured-center">
                {projects.map((project) => {
                    return (
                        <article className="tour-card" key={project.id}>
                            <div className="tour-img-container">
                                <img src={project.image} className="tour-img" alt=""/>
                                <p className="tour-date">{project.date}</p>
                            </div>
                            <div className="tour-info">
                                <div className="tour-title">
                                    <h4>{project.title}</h4>
                                </div>
                                <p>
                                    {project.info}
                                </p>
                            </div>
                        </article>
                    )
                })}
            </div>
        </section>
    )
}
export default Projects;