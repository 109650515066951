import {pageLinks,socialLinks} from "../data";

const Footer = () => {
    return (
        <footer className="section footer">
            <ul className="footer-links">
                {pageLinks.map((pages) => {
                    return (
                        <li key={pages.id}>
                            <a href={[pages.href]} className="footer-link">{pages.text}</a>
                        </li>
                    )
                })}
            </ul>
            <ul className="footer-icons">
                {socialLinks.map((social) => {
                    return (
                        <li key={social.id}>
                            <a href={social.href} target="_blank" className="footer-icon"
                            ><i className={social.icon}></i
                            ></a>
                        </li>
                    )
                })}
            </ul>
            <p className="copyright">
                copyright &copy; Rafael Polanski
                <span id="date">{new Date().getFullYear()}</span> all rights reserved
            </p>
        </footer>
    )
}
export default Footer;