const Hero = () => {
    return (
        <section className="hero" id="home">
            <div className="hero-banner">
                <h1>Rafael Polanski</h1>
                <p>
                    Portfolio
                </p>
                <a href="#services" className="btn hero-btn">Check my work</a>
            </div>
        </section>
    );
};
export default Hero;