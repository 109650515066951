import {pageLinks,socialLinks} from '../data'
import logo from '../images/icon.png'

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="nav-center">
                <div className="nav-header">
                    {/*<img src={logo} className="nav-logo" alt="logo"/>*/}
                    <button type="button" className="nav-toggle" id="nav-toggle">
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
                <ul className="nav-links" id="nav-links">
                    {pageLinks.map((data) => {
                        return(
                            <li key={data.id}>
                                <a href={data.href} className="nav-link"> {data.text} </a>
                            </li>
                        )
                        })
                    }
                </ul>

                <ul className="nav-icons">
                    {
                        socialLinks.map((data) => {
                            const {id, href, icon} = data
                            return (
                                <li key={id}>
                                    <a href={href} target="_blank" rel="noreferrer" className="nav-icon">
                                        <i className={icon}></i>
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </nav>
    )
}
export default Navbar