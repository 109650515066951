import img from '../images/me.JPG';
import Title from './Title';

const About = () =>{
    return (
    <section className="section" id="about">
        <Title title='about' subTitle='me'/>

        <div className="section-center about-center">
            <div className="about-img">
                <img
                    src={img}
                    className="about-photo"
                    alt="awesome beach"
                />
            </div>
            <article className="about-info">
                <h3>proven experience with digital transformations</h3>
                <p>
                    I spend a lot of time sharpening my business acumen, as well as my IT engineering skills
                </p>
                <p>
                    I have product development experience, focusing on customer
                    empathy interviews, pain points identification, facilitation of
                    ideation workshops, identification and execution of goals &
                    results, as well as the elaboration of Roadmaps for alignment
                    with leaders.
                </p>
                <p>
                    Still, I also have passion for algorithms, coding and also spend time refining my skills with
                    Python, Java, and React.
                </p>
                <p>
                    Truly believing that IoT and Machine Learning will continue to
                    change the world made me go return to studies and seek for a
                    Masters in Computer Science degree at Rice University.
                </p>
                <a href="#" className="btn">read more</a>
            </article>
        </div>
    </section>
    )
}
export default About